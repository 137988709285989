const experienceData = [
  {
    link: "https://chessnoor.khechoyan.xyz//",
    date: {
      start: "JAN",
      end: "MAY 2024",
    },
    title: "Frontend Engineer",
    company: "Picsart Academy",
    whatIDo: [
      "Developed from scratch a social platform for chess players using React.js.",
      "Created and integrated components for user interaction, including login, registration, and account management.",
      "Implemented a chess game within the web application, including game logic and user interface. ",
      "Integrated functionality for posts, likes, comments with replies, and their likes. ",
      "Developed a mechanism for adding friends and inviting them to participate in the game. ",
      "Created and managed user profiles with the ability to update personal information, set profile pictures, and banners. ",
      "Utilized Redux Toolkit for efficient state management.",
    ],
    links: [
      {link: "https://chessnoor.khechoyan.xyz//", title: "Chessnoor"},
      {link: "https://picsartacademy.am/", title: "Picsart Academy"},
    ],
    tags: [
      "React JS", "CSS"
    ],
  },

  {
    link: null,
    date: {
      start: "OCT 2023",
      end: "JAN 2024",
    },
    title: "Frontend Engineer",
    company: "Picsart Academy",
    whatIDo: [
      "Delepolment web platform for internal use",
      "Development and implementation of login and registration pages.",
      "Creation of password reset and forgot password pages.",
      "Implementation of user list with two display variants: one for users and one for admins",
    ],
    links: [
      {link: "https://picsartacademy.am/", title: "Picsart Academy"},
    ],
    tags: [
      "React JS",
      "TypeScript",
      "Axios",
      "JSS",
      "yup",
      "formik",
    ],
  },

  {
    link: null,
    date: {
      start: "FEB 2023",
      end: "(one day)",
    },
    title: "Event Participant",
    company: "Picsart",
    whatIDo: [
      "Involved in the development of a websiteintegrated with Picsart API for online imagecreation and generation."  
    ],
    links: [
      {link: "https://picsart.com/", title: "Picsart"}
    ],
    tags: [
      "React JS",
      "CSS"
    ],
  },

  {
    link: null,
    date: {
      start: "2021",
      end: "2022",
    },
    title: "Teaching Assistant",
    company: "Picsart Academy",
    whatIDo: [
      "Helped students with study questions andchecked homework."  
    ],
    links: [
      {link: "https://picsartacademy.am/", title: "Picsart Academy"},
    ],
    tags: [
      "C++",
    ],
  },
];

export default experienceData;
