// images
import FilmHubImg from '../assets/img/FilmHub.png';
import CraftSellImg from '../assets/img/CraftSell.png';
import fomponentImg from '../assets/img/fomponent.png';
import fastCssCopyImg from '../assets/img/fastCssCopy.png';
import interpreterImg from '../assets/img/interpreter.png';
import cleanUpReactImg from '../assets/img/cleanUpReact.png';

const selectedProjectsData = [
  {
    link: "https://khe4oyan.github.io/react_filmHub/",
    imgSrc: FilmHubImg,
    title: 'FilmHub',
    whatIs: 'Films list with ganres',
    notes: null,
  },
  {
    link: "https://khe4oyan.github.io/react-shop/",
    imgSrc: CraftSellImg,
    title: 'Buy Craft Sell',
    whatIs: 'Game where you can buy cases, craft items and sell',
    notes: null,
  },
  {
    link: "https://github.com/khe4oyan/FastCssCopy-vscode",
    imgSrc: fastCssCopyImg,
    title: 'Fast CSS Copy',
    whatIs: 'Is a extention for Visual Studio Code for Speed up development by copying styles from HTML to CSS with a single keyboard shortcut',
    notes: [
      {icon: 'download', title: '70+ installs'},
    ],
  },
  {
    link: "https://github.com/khe4oyan/fomponent-vscode",
    imgSrc: fomponentImg,
    title: 'Fomponent',
    whatIs: 'Fomponent is a extention for Visual Studio Code for instant creation of React.JS components in your project',
    notes: [
      {icon: 'download', title: '10+ installs'},
    ],
  },
  {
    link: "https://github.com/khe4oyan/CleanUpReact-vscode",
    imgSrc: cleanUpReactImg,
    title: 'Clean Up React',
    whatIs: 'Clean-Up React is a VS Code extension that automatically removes unnecessary files created when initializing a React project and cleans up the remaining files from unnecessary information.',
    notes: [
      {icon: 'download', title: '10+ installs'},
    ],
  },
  {
    link: "https://github.com/khe4oyan/picsart_academy_project_interpretator",
    imgSrc: interpreterImg,
    title: 'Interpreter',
    whatIs: 'An interpreter written in C++ that compiles a fictional programming language.',
    notes: null,
  },
];

export default selectedProjectsData;