// components
import SvgIcon from "../SvgIcon/SvgIcon";

// styles
import classes from "./styles.module.css";

export default function Project({ data }) {
  const { link, imgSrc, title, whatIs, notes } = data;

  return (
    <a href={link} target="_blank" rel="noreferrer" className={classes.root}>
      <img src={imgSrc} alt="project_preview" className={classes.preview} />
      <div className={classes.info}>
        <div className={classes.titleBox}>{title}</div>
        <div className={classes.whatIs}>{whatIs}</div>
        {notes && (
          <div className={classes.notes}>
            {notes.map((note, i) => (
              <div className={classes.note} key={i}>
                <SvgIcon iconName={note.icon} className={classes.icon} />
                {note.title}
              </div>
            ))}
          </div>
        )}
      </div>
    </a>
  );
}
